<template>
    <div class="gobiz">
        <secondary-header title="GoBiz" backRoute="default">
            <template v-slot:menu>
                <div class="main-menu text-only">
                    <ul class="nav nav-pills nav-fill container">
                        <li class="nav-item">
                            <a class="nav-item font-weight-bold">
                                <div class="text-dark">
                                    <span> {{ lang('My Address') }}</span>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </template>
            <template v-slot:right>
                <div class="home-button">
                    <router-link :to="{name:'user-bar'}" class="nav-item">
                        <font-awesome-icon icon="bars" size="lg"></font-awesome-icon>
                    </router-link>
                </div>
            </template>
        </secondary-header>

        <main>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-10 py-3">
                        <div v-for="address in customer_addresses" class="custom-control address p-1 border-bottom">
                            <label style="white-space:pre-line;" class="m-0">
                                <strong>{{ address.recipient_name }}</strong><br>
                                {{ address.phone_no }}<br>
                                {{ address.address_text }}
                            </label>
                            <a href="#" data-toggle="modal" data-target="#modal-new-address" class="edit-address large" @click.prevent="editAddress(address)"><font-awesome-icon :icon="['far','edit']" size="lg"></font-awesome-icon></a>
                        </div>
                    </div>

                    <float-bottom>
                        <div class="">
                            <a class="btn btn-secondary btn-sm text-center" data-toggle="modal" data-target="#modal-new-address">
                                <div class="text-center large">
                                    + {{ lang('Address') }}
                                </div>
                            </a>
                        </div>
                    </float-bottom>

                    <AddAddressModal v-on:address-created="newAddressCreated" v-on:address-modal-closed="clearDefaultValue" :default_value="customer_address" />
                </div>
            </div>
        </main>

        <loader v-if="state.loading"></loader>
        <main-footer></main-footer>
    </div>
</template>

<script>
import AddAddressModal from '@/components/AddAddressModal.vue'
export default {
    components: {AddAddressModal},

    data() {
        return {
            customer_addresses: [],
            address_id: '',
            customer_address: ''
        }
    },

    watch: {
        address_id: function(newVal) {
            // this.changeDefaultAddress
        }
    },

    methods: {
        loadCustomerAddresses() {
            this.$set(this.state, 'loading', true)
            axios.get(this.GLOBAL.API_BASE_URL +"customer-address")
                .then(response => {
                    this.customer_addresses = response.data
                })
                .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
                .finally(() => this.$set(this.state, 'loading', false))
        },

        newAddressCreated() {
            this.loadCustomerAddresses()
        },

        editAddress(address) {
            this.customer_address = address
        },

        clearDefaultValue() {
            this.customer_address = ''
        }
    },

    created() {
        this.loadCustomerAddresses()
    },
}
</script>

<style lang="css" scoped>
.edit-address {
    position: absolute;
    right: 0;
    bottom: 0.5rem;
}
</style>
