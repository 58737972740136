<template>
    <nav>
        <div>
            <div class="top-logo text-center font-weight-bold">
                <div v-if="backRoute" class="back-button">
                    <a href="#" v-if="backRoute == 'default'" @click.prevent="goBack(-1)"><font-awesome-icon icon="chevron-left" size="lg"></font-awesome-icon></a>
                    <a href="#" v-else-if="backRoute == 'close_app'" @click.prevent="closeApp()"><font-awesome-icon icon="chevron-left" size="lg"></font-awesome-icon></a>
                    <router-link v-else :to="backRoute"><font-awesome-icon icon="chevron-left" size="lg"></font-awesome-icon></router-link>
                </div>
                <div class="title">
                    {{ title }}
                </div>
                <div v-if="homeButton" class="home-button">
                    <a href="#" @click.prevent="goHome">
                        <!-- <img src="@/assets/images/icons/home-white.png" alt=""> -->
                    </a>
                </div>
                <slot name="right"></slot>
            </div>
            <slot name="menu"></slot>
        </div>
    </nav>
</template>

<script>
export default {
    props: ['title', 'backRoute', 'homeButton'],

    methods: {
        closeApp() {
            // close browser on native app
            window.ReactNativeWebView.postMessage("WINDOW_CLOSED")
        }
    }
}
</script>
