<template>
    <div class="remove-x-margin" style="margin-top: -17px;">
        <div class="image-slider">
            <div class="owl-carousel owl-theme">
                <div v-for="image_url in latest_product.product_image_regular_url" class="item">
                    <div :style="'background-image: url('+image_url+');'" class="product-image"></div>
                </div>
            </div>
        </div>

        <div class="p-2 px-3 border-top">
            <div class="product-name">
                {{ latest_product.product_name }}
            </div>
            <div class="details">
                <span class="float-right font-weight-bold large"><span class="text-danger">{{ latest_product.price }}</span>/{{ latest_product.unit}}</span>
                <span class="large">{{ lang('By') }} {{ latest_product.branch_name }}</span> <br>
                <span class="large">{{ latest_product.branch_phone_no }}</span> <br>
                {{ lang('Transacted') }}: {{ latest_product.total_transacted }} <br>
                {{ latest_product.city }}, {{ latest_product.state }} <br>
                {{ latest_product.description }}
            </div>
        </div>

        <div class="p-2 px-3 border-bottom navigation">
            <div>
                <a :href="latest_product.tnc_link ? ('https://drive.google.com/viewerng/viewer?embedded=true&url='+ latest_product.tnc_link) : ''" class="text-confirm">T&C</a> | <a :href="'https://maps.google.com/maps?daddr='+ latest_product.latitude +','+ latest_product.longitude" class="text-confirm">Location</a>
                <div class="navigation-icons float-right">
                    <img src="@/assets/action_icon/CHAT.png" alt="" class="icon">
                    <favourite-icon :product="latest_product" />
                    <img src="@/assets/action_icon/MERCHANT.png" alt="" class="icon" @click="$parent.viewBranch(product_branch)">
                    <img src="@/assets/action_icon/SHARE.png" alt="" class="icon">
                    <img v-if="latest_product.is_halal" src="@/assets/action_icon/HALAL.png" alt="" class="icon">
                </div>
            </div>
        </div>

        <div class="m-2">
            <div style="overflow-x:auto;">
                <table width="100%">
                    <tr>
                        <th class="text-left px-2">{{ lang('Variant') }}</th>
                        <th class="text-right px-2">{{ lang('Stock') }}</th>
                        <th class="px-2">{{ lang('RM') }}</th>
                        <th class="text-center">{{ lang('Quantity') }}</th>
                    </tr>
                    <tr v-for="variant in latest_product.variants" :class="{'text-danger': quantity[variant.id] > variant.current_total}">
                        <td class="text-left px-2">{{ variant.name }}</td>
                        <td class="text-right px-2">{{ variant.current_total }}</td>
                        <td class="px-2">{{ variant.rate }}</td>
                        <td class="text-center counter" style="width: 7.7rem;">
                            <div class="input-group" style="width: 7.7rem;">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" @click="deductQuantity(variant)">-</span>
                                </div>
                                <input type="text" class="form-control text-center" :value="quantity[variant.id]" readonly>
                                <div class="input-group-append">
                                    <span class="input-group-text" @click="addQuantity(variant)">+</span>
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>

        <float-bottom v-if="latest_product.is_allow_buy">
            <div class="">
                <a href="#" @click.prevent="addToCart" class="btn btn-primary text-white" :class="{disabled : !can_proceed}">
                    <div class="tex-center">
                        {{ lang('Add to My Cart') }}
                    </div>
                </a>
            </div>
            <div class="">
                <router-link :to="{name: 'product.checkout', params: {products: [latest_product], quantity: quantity} }" class="btn btn-secondary" :class="{disabled : !can_proceed}">
                    <div class="tex-center">
                        {{ lang('Check Out') }} <br>
                        ({{ money(total_amount) }})
                    </div>
                </router-link>
            </div>
        </float-bottom>
    </div>
</template>

<script>
export default {
    props: ['fudc', 'keyword', 'product', 'prev_quantity', 'product_branch'],

    data() {
        return {
            latest_product: {},
            quantity: this.prev_quantity ? this.prev_quantity : {}
        }
    },

    computed: {
        total_amount: function() {
            var total = 0
            if(this.latest_product.variants) {
                for (var key in this.quantity) {
                    if (this.quantity.hasOwnProperty(key)) {
                        var variant = this.latest_product.variants.find(element => element.id == key)
                        total += this.quantity[key] * variant.rate
                    }
                }
            }
            return total
        },

        can_proceed: function() {
            if(this.latest_product.variants) {
                for (var key in this.quantity) {
                    if (this.quantity.hasOwnProperty(key)) {
                        var variant = this.latest_product.variants.find(element => element.id == key)
                        if(this.quantity[key] >  variant.current_total) {
                            return false
                        }
                    }
                }
            }
            return this.total_amount > 0
        }
    },

    methods: {
        loadProduct() {
            this.latest_product = this.product
            this.$set(this.$parent.state, 'loading', true)
            axios.get(this.GLOBAL.API_BASE_URL +"product/"+ this.product.id)
                .then(response => {
                    this.latest_product = response.data
                    this.initiateOwlCarousel()
                })
                .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
                .finally(() => this.$set(this.$parent.state, 'loading', false))
        },

        deductQuantity(variant) {
            this.$set(this.quantity, variant.id, (this.quantity[variant.id] || 0) - 1)
            if(this.quantity[variant.id] < 0) {
                this.quantity[variant.id] = 0
            }
            if(this.quantity[variant.id] > variant.current_total) {
                this.quantity[variant.id] = variant.current_total
                if(this.quantity[variant.id] < 0) {
                    this.quantity[variant.id] = 0
                }
            }
        },

        addQuantity(variant) {
            this.$set(this.quantity, variant.id, (this.quantity[variant.id] || 0) + 1)
            if(this.quantity[variant.id] > variant.current_total) {
                this.quantity[variant.id] = variant.current_total
                if(this.quantity[variant.id] < 0) {
                    this.quantity[variant.id] = 0
                }
            }
        },

        addToCart() {
            this.$set(this.$parent.state, 'loading', true)
            axios.post(this.GLOBAL.API_BASE_URL +"customer-cart", {product_id: this.latest_product.id, quantity:this.quantity})
                .then(response => {
                    this.$router.go(-1)
                })
                .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
                .finally(() => this.$set(this.$parent.state, 'loading', false))
        },

        setView() {
            axios.get(this.GLOBAL.API_BASE_URL +"product/"+ this.product.id +"/set-view")
                .then(response => {
                })
                .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
        },

        initiateOwlCarousel() {
            setTimeout(() => {
                $('.owl-carousel').owlCarousel({
                    margin:10,
                    nav:true,
                    responsive:{
                        0:{
                            items:1
                        },
                    }
                });
                $('.owl-carousel').owlCarousel('refresh');
            }, 2000)
        }
    },

    created() {
        this.loadProduct()
    },

    mounted() {
        this.setView()

        $('body').addClass('remove-header-menu')

        $(document).ready(() => {
            this.initiateOwlCarousel()
        });
    },

    destroyed () {
        $('body').removeClass('remove-header-menu')
    },

    beforeRouteEnter(to, from, next) {
        if(from.name == 'product.checkout') {
            to.params['product'] = from.params.products[0]
        }
        if(to.params['product'] == undefined) {
            next({name: 'main-menu', replace: true})
        }
        else {
            next()
        }
    },

    beforeRouteLeave(to, from, next) {
        to.params['fudc'] = this.fudc
        to.params['keyword'] = this.keyword
        to.params['product_branch'] = this.product_branch
        next()
    }
}
</script>

<style lang="scss" scoped>
    .navigation {
        display: block;
    }

    .image-slider {
        min-height: 400px;
    }

    .product-image {
        height: 400px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }

    .counter {
        span {
            background-color: white;
            cursor: pointer;
            font-size: 1.3rem;
            padding: 0.2rem 0.7rem;
            line-height: 1;
        }
        input {
            background-color: white;
            width: 5px;
            font-size: 1rem;
            padding: 0.2rem;
            line-height: 1;
        }
    }
</style>
