<template>
    <div class="product-branch remove-x-margin">
        <table class="branch-list">
            <template v-for="product_branch in $parent.branches.data" v-if="!$parent.is_halal || ($parent.is_halal && product_branch.is_halal)">
                <tr>
                    <td rowspan="3" class="left">
                        <div class="image">
                            <img @click="viewBranchMenu(product_branch)" :src="product_branch.branch_logo_url" alt="">
                        </div>
                        <div class="small my-1">
                            {{ product_branch.branch_ref_no }}
                        </div>
                    </td>
                    <td class="right">
                        <div class="product-name">
                            {{ product_branch.branch_name }}
                        </div>
                        <div class="details">
                            {{ product_branch.branch_phone_no }} <br>
                            {{ product_branch.city }}, {{ product_branch.state }} <br>
                            {{ lang('FUDC') }}: {{ fudc.name }} <br>
                            {{ product_branch.description }}
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="right-middle">
                        <table class="text-center rating" width="100%">
                            <tr>
                                <td class="text-warning">
                                    {{ product_branch.total_trasaction }}
                                </td>
                                <td class="text-warning">
                                    {{ product_branch.rating }}
                                </td>
                                <td class="text-warning">
                                    {{ product_branch.chat_response_percent }}
                                </td>
                            </tr>
                            <tr>
                                <td class="small">
                                    {{ lang('Transaction') }}
                                </td>
                                <td class="small">
                                    {{ lang('Rating') }}
                                </td>
                                <td class="small">
                                    {{ lang('Chat Response') }}
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td class="right-last">
                        <div class="row no-gutters">
                            <div class="navigation col-7">
                                <div>
                                    <a :href="product_branch.tnc_link ? ('https://drive.google.com/viewerng/viewer?embedded=true&url='+ product_branch.tnc_link) : ''" class="text-confirm">T&C</a> | <a :href="'https://maps.google.com/maps?daddr='+ product_branch.latitude +','+ product_branch.longitude" class="text-confirm" >Location</a>
                                    <img src="@/assets/action_icon/CHAT.png" alt="" class="icon ml-2">
                                </div>
                            </div>
                            <div class="col-5">
                                <button class="btn btn-outline-warning btn-sm btn-block" @click="viewBranchMenu(product_branch)">{{ lang('View Menu') }}</button>
                            </div>
                        </div>
                    </td>
                </tr>
            </template>
            <tr v-if="this.state.loading_next">
                <td colspan="2" class="text-center p-3">{{ lang('loading') }}</td>
            </tr>
            <tr v-if="!this.$parent.state.loading && this.$parent.branches.meta.total == 0">
                <td colspan="2" class="text-center p-3">{{ lang('No Data') }}</td>
            </tr>
        </table>
    </div>
</template>

<script>
export default {
    props: ['fudc', 'keyword'],

    methods: {
        loadProduct() {
                this.$set(this.$parent.state, 'loading', true)
                axios.post(this.GLOBAL.API_BASE_URL +"product/branch", {fudc_id: this.$parent.fudc.id, keyword: this.$parent.keyword})
                    .then(response => {
                        this.$parent.branches.data = response.data
                    })
                    .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
                    .finally(() => this.$set(this.$parent.state, 'loading', false))
        },

        viewBranchMenu(product_branch) {
            this.$router.push({ name: 'product.branch.menu-list', params: {product_branch: product_branch} })
        }
    },

    created() {
        this.$parent.ignore_filter = false
        if(this.$parent.branches.data.length == 0) {
            this.loadProduct()
        }
    },

    beforeRouteLeave(to, from, next) {
        to.params['fudc'] = this.fudc
        to.params['keyword'] = this.keyword
        next()
    }
}
</script>
