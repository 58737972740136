<template>
    <div class="text-center py-5">
        Processing...
        <form id="gopay-form" class="content pt-4" :action="GLOBAL.MAIN_GONET_BACKEND_URL + '/ewallet-payment'" method="POST" hidden>
            <input v-for="(value, field) in payment_details.gopay" type="hidden" :name="field" :value="value">
            <div class="">
                <button type="submit" class="btn btn-primary btn-block">{{ lang('GoPay') }}</button>
            </div>
        </form>
        <loader v-if="true"></loader>
    </div>
</template>

<script>
export default {
    props: ['products', 'quantity', 'delivery_details'],

    data() {
        return {
            payment_details: {}
        }
    },

    methods: {
        getPaymentDetails() {
            this.$set(this.state, 'loading', true)
            axios.post(this.GLOBAL.API_BASE_URL + "payment-details", {
                    products: this.products,
                    quantity: this.quantity,
                    delivery_details: this.delivery_details,
                })
                .then(response => {
                    this.payment_details = response.data

                    setTimeout(function(){
                        $('#gopay-form').submit()
                    }, 1000);
                })
                .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
                .finally(() => this.$set(this.state, 'loading', false))
        }
    },

    created() {
        this.getPaymentDetails()
    },

    mounted() {
        $('body').addClass('remove-header-menu')
    },

    destroyed () {
        $('body').removeClass('remove-header-menu')
    },

    beforeRouteEnter (to, from, next) {
        if(to.params.products == undefined) {
            next({name: 'main-menu', replace: true})
        }
        else {
            next()
        }
    },
}
</script>

<style lang="css" scoped>
</style>
