<template>
    <div class="gobiz">
        <secondary-header title="GoBiz" backRoute="default">
            <template v-slot:menu>
                <div class="main-menu text-only">
                    <ul class="nav nav-pills nav-fill container">
                        <li class="nav-item">
                            <router-link :to="{ name: 'product.menu' }" class="nav-item" replace>
                                <span> {{ lang('Menu') }}</span>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{ name: 'product.branch' }" class="nav-item" replace>
                                <span> {{ lang('Merchant') }}</span>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </template>
            <template v-slot:right>
                <div class="home-button">
                    <router-link :to="{name:'user-bar'}" class="nav-item">
                        <font-awesome-icon icon="bars" size="lg"></font-awesome-icon>
                    </router-link>
                </div>
            </template>
        </secondary-header>

        <main>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-10 py-3">

                        <div class="font-weight-bold pb-2 row filtering">
                            <div class="col-8">
                                <div class="">
                                    {{ lang('FUDC') }} : {{ fudc.name }}
                                </div>
                                <div class="custom-control custom-checkbox custom-control-inline">
                                    <input type="checkbox" class="custom-control-input" id="is_halal" v-model="is_halal">
                                    <label class="custom-control-label" for="is_halal">{{ lang('Halal') }}</label>
                                </div>

                                <div class="custom-control custom-checkbox custom-control-inline">
                                    <input type="checkbox" class="custom-control-input" id="is_available" v-model="is_available">
                                    <label class="custom-control-label" for="is_available">{{ lang('Available') }}</label>
                                </div>
                            </div>
                            <div class="col-4 text-right state-select">
                                <select id="select-state" class="form-control" v-model="state_selected">
                                    <option value="">{{ lang('All') }}</option>
                                    <option v-for="state in state_options" :value="state">{{ state }}</option>
                                </select>
                                <div class="flag-span">
                                    <img :src="getImgUrl" height="36" class="border" alt="">
                                    <!-- <div class="state">{{ state_selected }}</div> -->
                                </div>
                            </div>
                        </div>
                        <div class="py-2 filtering">
                            <div v-if="state_selected" class="input-group secondary mb-2">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1"><font-awesome-icon :icon="['fas', 'map-marker-alt']" size="lg"></font-awesome-icon></span>
                                </div>
                                <select class="form-control form-control-lg" v-model="city_selected">
                                    <option value="">{{ lang('All') }}</option>
                                    <option v-for="city in city_options" v-if="state_selected == 'all' || city.state == state_selected" :value="city.city">{{ city.city }}</option>
                                </select>
                            </div>
                            <select class="form-control form-control-lg mb-2" v-model="group_selected">
                                <option value="">{{ lang('All') }}</option>
                                <option v-for="group in group_options" :value="group.id">{{ group.name }}</option>
                            </select>
                        </div>

                        <router-view />

                    </div>
                </div>
            </div>
        </main>

        <main-footer></main-footer>
        <loader v-if="state.loading"></loader>
    </div>
</template>

<script>
export default {
    props: ['fudc', 'keyword'],

    data() {
        return {
            ignore_filter: false,

            is_halal: false,
            is_available: false,

            default_change: false,

            state_flag: {
                Johor: require('@/assets/flag/johor.png'),
                Kedah: require('@/assets/flag/kedah.png'),
                Kelantan: require('@/assets/flag/kelantan.png'),
                Malacca: require('@/assets/flag/malacca.png'),
                Melaka: require('@/assets/flag/malacca.png'),
                Malaysia: require('@/assets/flag/malaysia.png'),
                'Negeri Sembilan': require('@/assets/flag/negeri sembilan.png'),
                Pahang: require('@/assets/flag/pahang.png'),
                Penang: require('@/assets/flag/penang.png'),
                'Pulau Pinang': require('@/assets/flag/penang.png'),
                Perak: require('@/assets/flag/perak.png'),
                Perlis: require('@/assets/flag/perlis.png'),
                Sabah: require('@/assets/flag/sabah.png'),
                Sarawak: require('@/assets/flag/sarawak.png'),
                Selangor: require('@/assets/flag/selangor.png'),
                Terengganu: require('@/assets/flag/terengganu.png'),
                all: require('@/assets/flag/malaysia.png'),
                '': require('@/assets/flag/malaysia.png'),
                'Wilayah Persekutuan Kuala Lumpur': require('@/assets/flag/kuala lumpur.png'),
                'Kuala Lumpur': require('@/assets/flag/kuala lumpur.png'),
                'Wilayah Persekutuan Labuan': require('@/assets/flag/labuan.png'),
                Labuan: require('@/assets/flag/labuan.png'),
            },

            state_options: [],
            city_options: [],
            group_options: [],

            state_selected: '',
            city_selected: '',
            group_selected: '',

            products: {
                data: [],
                meta: {},
            },

            branches: {
                data: [],
                meta: {},
            },
            branch_products: {
                data: [],
                meta: {},
            },
        }
    },

    watch: {
        'products.meta.user_state': function() {
            this.setDefaultStateCity()
        },

        'products.meta.user_city': function() {
            this.setDefaultStateCity()
        },

        state_selected: function() {
            if(!this.default_change) {
                this.city_selected = ''
            }
            this.default_change = false
        }
    },

    computed: {
        getImgUrl: function() {
            return this.state_flag[this.state_selected]
        }
    },

    methods: {
        loadProductFilteringOptions() {
            axios.post(this.GLOBAL.API_BASE_URL +"product/filtering-options", {fudc_id: this.fudc.id})
                .then(response => {
                    this.state_options = response.data.states
                    this.city_options = response.data.cities
                    this.group_options = response.data.groups
                })
                .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
        },

        viewBranch(product_branch) {
            this.ignore_filter = true
            this.$router.push({ name: 'product.branch.menu-list', params: {product_branch: product_branch} })
        }
    },

    created() {
        this.loadProductFilteringOptions()
    },

    beforeRouteEnter (to, from, next) {
        if(to.params.fudc == undefined) {
            next({name: 'main-menu', replace: true})
        }
        else {
            next()
        }
    },
}
</script>

<style lang="scss">
    .state-select {
        #select-state {
            z-index: 2;
            opacity: 0;
            height: 30px;
        }
        .flag-span {
            z-index: -1;
            position: absolute;
            top: 0;
            right: 1rem;
        }
    }

    .remove-header-menu {
        .main-menu,
        .filtering {
            display: none !important;
        }

        main {
            padding-top: 3.6rem !important;
        }
    }

    .remove-filtering {
        .filtering {
            display: none !important;
        }
    }

    table {
        &.product-list {
            td {
                &.left {
                    width: 20%;
                    text-align: center;
                    border: 1px solid #dee2e6;
                    border-right: unset;
                    padding: 0.5rem 0.5rem 0.5rem 0.8rem;
                }
                &.right {
                    width: 70%;
                    border-top: 1px solid #dee2e6;
                    border-right: 1px solid #dee2e6;
                    padding: 0.5rem 0.8rem 0.5rem 0.5rem;
                }
                &.right-last {
                    border-right: 1px solid #dee2e6;
                    border-bottom: 1px solid #dee2e6;
                    padding: 0.5rem 0.8rem 0.5rem 0.5rem;
                }
            }
        }

        &.branch-list {
            td {
                &.left {
                    width: 25%;
                    text-align: center;
                    padding: 0.5rem 0.5rem 0.5rem 1rem;
                    border: 1px solid #dee2e6;
                }
                &.right {
                    width: 65%;
                    padding: 0.5rem 1rem 0.5rem 0.5rem;
                    border-top: 1px solid #dee2e6;
                    border-right: 1px solid #dee2e6;
                }
                &.right-middle {
                    padding: 0.5rem 1rem 0.5rem 0.5rem;
                    border-right: 1px solid #dee2e6;
                }
                &.right-last {
                    vertical-align: bottom;
                    padding: 0.5rem 1rem 0.5rem 0.5rem;
                    border-right: 1px solid #dee2e6;
                    border-bottom: 1px solid #dee2e6;
                }
            }
        }
    }

    .image {
        // .watermark {
        //     position: relative;
        //     display: block;
        //     min-height: 40px;
        //
        //     &:after {
        //         content: "";
        //         display: block;
        //         width: 100%;
        //         height: 100%;
        //         position: absolute;
        //         top: 0px;
        //         left: 0px;
        //         background-image: url('../../assets/logo.png');
        //         background-size: 30px 30px;
        //         background-position: 20% 20%;
        //         background-repeat: no-repeat;
        //         opacity: 0.9;
        //     }
        // }

        img {
            max-width: 30vw;
            max-height: 200px;
        }
    }

    .product-image {
        position: relative;
        display: block;

        // &:after {
        //     content: "";
        //     display: block;
        //     width: 100%;
        //     height: 100%;
        //     position: absolute;
        //     top: 0px;
        //     left: 0px;
        //     background-image: url('../../assets/logo.png');
        //     background-size: 80px 80px;
        //     background-position: 20% 20%;
        //     background-repeat: no-repeat;
        //     opacity: 0.9;
        // }
    }

    .view {
        display: flex;
        align-items: center;

        img {
            padding-bottom: 2.82px;
            padding-right: 3px;
        }
    }

    .product-name {
        font-weight: bold;
        font-size: 0.96rem;
    }

    .details {
        line-height: 1.4;
        white-space: pre-line;

    }

    .navigation {
        align-items: flex-end;
        display: flex;

        .navigation-icons {
            display: flex;
            flex-wrap: wrap;
        }

        .icon {
            margin-right: 1rem;
            margin-bottom: 0.2rem;
            width: 1.2rem;
            height: 1.2rem;
        }
    }

    .small {
        font-size: 75%;
    }
    .large {
        font-size: 120%;
        line-height: 1.5;
    }

    .rating {
        color: #949494;
    }

    .btn.btn-sm {
        line-height: 1rem !important;
        font-size: 0.7rem;
    }
</style>
