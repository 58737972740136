<template>
    <div class="gobiz">
        <secondary-header title="GoBiz" backRoute="close_app">
            <template v-slot:menu>
                <div class="main-menu text-only">
                    <ul class="nav nav-pills nav-fill container">
                        <li class="nav-item">
                            <router-link :to="{name: 'my-order'}" class="nav-item">
                                <div class="text-dark">
                                    <img src="@/assets/action_icon/grey/MY_ORDER.png" alt="" height="25">
                                    <span> {{ lang('My Order') }}</span>
                                </div>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{name: 'history'}" class="nav-item">
                                <div class="text-dark">
                                    <img src="@/assets/action_icon/grey/HISTORY.png" alt="" height="25">
                                    <span> {{ lang('My History') }}</span>
                                </div>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </template>
            <template v-slot:right>
                <div class="home-button">
                    <router-link :to="{name:'user-bar'}" class="nav-item">
                        <font-awesome-icon icon="bars" size="lg"></font-awesome-icon>
                    </router-link>
                </div>
            </template>
        </secondary-header>

        <main>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-10">
                        <div class="row fudc py-1 px-3">
                            <div class="col-6 summary">
                                <b>{{ lang('Merchants') }}</b>: {{ number(summary.total_branch) }}
                            </div>
                            <div class="col-6 summary text-right">
                                {{ number(summary.total_product_variant) }} <b>{{ lang('Items') }}</b>
                            </div>

                            <input name="keyword" class="form-control form-control-lg mb-2" v-model="keyword" :placeholder="lang('Search')" />


                            <select name="fudc_selection" class="form-control form-control-lg mb-3" v-model="fudc_selection">
                                <option value="">{{ lang('-- Select Category --') }}</option>
                                <option :value="{name:lang('All'),code:null,id:null}">{{ lang('All') }}</option>
                                <option v-for="fudc in fudcs" :value="fudc">{{ fudc.name }}</option>
                            </select>

                            <div v-for="fudc in fudcs" class="col-4 text-center">
                                <img :src="fudc.icon_url" alt="" @click="selectFudc(fudc)">
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </main>

        <main-footer></main-footer>
        <loader v-if="state.loading"></loader>
    </div>
</template>

<script>
import _ from 'lodash'

export default {
    data() {
        return {
            summary: {},

            keyword: '',

            fudcs: [],

            fudc_selection: '',
        }
    },

    watch: {
        fudc_selection: function(newVal) {
            this.selectFudc(newVal)
        },

        keyword: function(newVal) {
            this.proceed(newVal, this)
        }
    },

    methods: {
        getSummary() {
            axios.get(this.GLOBAL.API_BASE_URL +"summary")
                .then(response => {
                    this.summary = response.data
                })
                .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
        },

        getFudc() {
            this.$set(this.state, 'loading', true)
            axios.get(this.GLOBAL.API_BASE_URL +"fudc/list")
                .then(response => {
                    this.fudcs = response.data
                })
                .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
                .finally(() => this.$set(this.state, 'loading', false))
        },

        selectFudc(fudc) {
            this.$router.push({ name: 'product.menu', params: {fudc: fudc} })
        },

        proceed: _.debounce((keyword, vm) => {
            if(keyword.length >= 3) {
                vm.$router.push({ name: 'product.menu', params: {fudc: {name:vm.lang('All'),code:null,id:null}, keyword: keyword} })
            }
        }, 1000)
    },

    created() {
        this.getSummary()
        this.getFudc()
    }
}
</script>

<style lang="scss" scoped>
    .nav-item:not(:last-child) {
        border-right: 1px solid #e3e3e3;
    }

    .summary {
        font-size: 0.9rem;
    }
</style>
