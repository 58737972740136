<template>
    <div class="gobiz single-header">
        <secondary-header title="GoBiz" backRoute="default"></secondary-header>

        <main>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-10 py-3 bar-list">
                        <router-link :to="{ name: 'my-address' }" class="btn btn-block btn-lg btn-outline-primary text-left">
                            <div class="icon">
                                <img src="@/assets/action_icon/yellow/MY_ADDRESS.png" alt="">
                            </div>
                            {{ lang('My Address') }}
                        </router-link>

                        <router-link :to="{ name: 'my-favourite'}" class="btn btn-block btn-lg btn-outline-primary text-left">
                            <div class="icon">
                                <img src="@/assets/action_icon/yellow/MY_FAVORITE.png" alt="">
                            </div>
                            {{ lang('My Favourite') }}
                        </router-link>

                        <router-link :to="{ name: 'my-cart' }" class="btn btn-block btn-lg btn-outline-primary text-left">
                            <div class="icon">
                                <img src="@/assets/action_icon/yellow/MY_CART.png" alt="">
                            </div>
                            {{ lang('My Cart') }}
                        </router-link>

                        <router-link :to="{name: 'my-order'}" class="btn btn-block btn-lg btn-outline-primary text-left">
                            <div class="icon">
                                <img src="@/assets/action_icon/yellow/MY_ORDER.png" alt="">
                            </div>
                            {{ lang('My Order') }}
                        </router-link>

                        <router-link :to="{name: 'history'}" class="btn btn-block btn-lg btn-outline-primary text-left">
                            <div class="icon">
                                <img src="@/assets/action_icon/yellow/HISTORY.png" alt="">
                            </div>
                            {{ lang('My History') }}
                        </router-link>

                        <router-link :to="{ name: '', params: {} }" class="btn btn-block btn-lg btn-outline-primary text-left wip">
                            <div class="icon">
                                <img src="@/assets/action_icon/yellow/CHAT.png" alt="">
                            </div>
                            {{ lang('My Chart') }}
                        </router-link>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
    .bar-list {
        > a {
            display: flex;
        }

        .icon {
            width: 2rem;
            margin-right: 1rem;

            img {
                display: block;
                margin: auto;
                height: 1.6rem;
            }
        }
    }
</style>
