<template>
    <div class="product-menu remove-x-margin">
        <table class="product-list">
            <template v-for="product in this.$parent.products.data">
                <tr v-if="(!$parent.is_halal || ($parent.is_halal && product.is_halal)) && (!$parent.is_available || ($parent.is_available && product.total_available > 0))" :key="'tr1-'+ product.id">
                    <td rowspan="2" class="left">
                        <div class="ref-no small">
                            {{ product.product_code }}
                        </div>
                        <div class="image">
                            <router-link v-if="product.is_allow_buy" :to="{ name:'product.add-to-cart', params: {product: product, product_branch: {branch_ref_no: product.branch_ref_no }} }" class="watermark">
                                <img :src="product.product_image_thumbnail_url[0]" alt="">
                            </router-link>
                            <span v-else class="watermark">
                                <img :src="product.product_image_thumbnail_url[0]" alt="">
                            </span>
                        </div>
                        <div class="view small my-1">
                            <img src="@/assets/action_icon/VIEW.png" alt="" width="21"> {{ product.total_view }}
                        </div>
                    </td>
                    <td class="right">
                        <div class="product-name">
                            {{ product.product_name }}
                        </div>
                        <div class="details">
                            <span class="float-right font-weight-bold"><span class="text-danger">{{ product.price }}</span>/{{ product.unit}}</span>
                            {{ lang('Transacted') }}: {{ product.total_transacted }} <br>
                            {{ lang('Availability') }}: <span v-if="product.total_available > 0">{{ product.total_available }}</span> <span v-else class="text-warning">{{ lang('Sold Out') }}</span> <br>
                            {{ product.city }}, {{ product.state }} <br>
                        </div>
                    </td>
                </tr>
                <tr v-if="(!$parent.is_halal || ($parent.is_halal && product.is_halal)) && (!$parent.is_available || ($parent.is_available && product.total_available > 0))" :key="'tr2-'+ product.id">
                    <td class="right-last">
                        <div class="row no-gutters">
                            <div class="navigation col-7">
                                <div>
                                    <a :href="product.tnc_link ? ('https://drive.google.com/viewerng/viewer?embedded=true&url='+ product.tnc_link) : ''" class="text-confirm">T&C</a> | <a :href="'https://maps.google.com/maps?daddr='+ product.latitude +','+ product.longitude" class="text-confirm">Location</a>
                                    <div class="mt-1 navigation-icons">
                                        <img src="@/assets/action_icon/CHAT.png" alt="" class="icon">
                                        <favourite-icon :product="product" />
                                        <img src="@/assets/action_icon/MERCHANT.png" alt="" class="icon" @click="$parent.viewBranch({branch_ref_no: product.branch_ref_no })">
                                        <img src="@/assets/action_icon/SHARE.png" alt="" class="icon">
                                        <img v-if="product.is_halal" src="@/assets/action_icon/HALAL.png" alt="" class="icon">
                                    </div>
                                </div>
                            </div>
                            <div class="col-5">
                                <button class="btn btn-secondary btn-sm btn-block wip disabled">{{ lang('Booking') }}</button>
                                <router-link :to="{ name:'product.add-to-cart', params: {product: product, product_branch: {branch_ref_no: product.branch_ref_no }} }" class="btn btn-primary btn-sm btn-block text-white" :class="{disabled: !product.is_allow_buy, 'btn-disabled': !product.is_allow_buy}">{{ lang('Add to cart') }}</router-link>
                            </div>
                        </div>
                    </td>
                </tr>
            </template>
            <tr v-if="this.state.loading_next">
                <td colspan="2" class="text-center p-3">{{ lang('loading') }}</td>
            </tr>
            <tr v-if="!this.$parent.state.loading && this.$parent.products.meta.total == 0">
                <td colspan="2" class="text-center p-3 font-weight-bold text-center" style="font-size: 1.3rem;">{{ lang('Item Not Found') }}</td>
            </tr>
        </table>
    </div>
</template>

<script>
export default {
    props: ['fudc', 'keyword'],

    watch: {
        '$parent.state_selected': function(newVal) {
            this.loadProduct()
        },
        '$parent.city_selected': function(newVal) {
            this.loadProduct()
        },
        '$parent.group_selected': function(newVal) {
            this.loadProduct()
        }
    },

    methods: {
        loadProduct() {
            this.$set(this.$parent.state, 'loading', true)
            axios.post(this.GLOBAL.API_BASE_URL +"product/menu", {
                    keyword: this.$parent.keyword,
                    fudc_id: this.$parent.fudc.id,
                    state: this.$parent.state_selected,
                    city: this.$parent.city_selected,
                    group: this.$parent.group_selected
                })
                .then(response => {
                    this.$parent.products.data = response.data.data
                    this.$parent.products.next_page = response.data.links.next
                    this.$parent.products.meta = response.data.meta
                })
                .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
                .finally(() => this.$set(this.$parent.state, 'loading', false))
        },

        loadProductNextPage() {
            let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight + 1 >= document.documentElement.offsetHeight
            if(this.$parent.products.next_page && bottomOfWindow) {
                this.$set(this.state, 'loading_next', true)
                axios.post(this.GLOBAL.API_BASE_URL +"product/menu?page="+ this.$parent.products.meta.next_page, {
                        keyword: this.$parent.keyword,
                        fudc_id: this.$parent.fudc.id,
                        state: this.$parent.state_selected,
                        city: this.$parent.city_selected,
                        group: this.$parent.group_selected
                    })
                    .then(response => {
                        this.$parent.products.data.push.apply(this.$parent.products.data, response.data.data)
                        this.$parent.products.next_page = response.data.links.next
                        this.$parent.products.meta = response.data.meta
                        this.loadProductNextPage()
                    })
                    .catch(error=>defaultErrorManagement(error.response))
                    .finally(() => this.$set(this.state, 'loading_next', false))

                this.$parent.products.next_page = ''
            }
        },
    },

    created() {
        if(this.$parent.products.data.length <= 0) {
            this.loadProduct()
        }

        window.addEventListener('scroll', this.loadProductNextPage);
    },

    destroyed () {
        window.removeEventListener('scroll', this.loadProductNextPage);
    },

    beforeRouteLeave(to, from, next) {
        to.params['fudc'] = this.fudc
        to.params['keyword'] = this.keyword
        next()
    }
}
</script>
