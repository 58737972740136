import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'owl.carousel/dist/assets/owl.theme.green.css';
import 'owl.carousel';

import './sass/app.scss'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faChevronLeft, faSpinner, faLightbulb, faMapMarkerAlt, faBars, faTrash, faStar as faStarS } from '@fortawesome/free-solid-svg-icons'
import { faLightbulb as faLightbulb2, faEdit, faStar } from '@fortawesome/free-regular-svg-icons'
library.add(faChevronLeft, faSpinner, faLightbulb, faLightbulb2, faMapMarkerAlt, faBars, faEdit, faTrash, faStar, faStarS)

require('bootstrap');
window.axios = require('axios')
window.Swal = require('sweetalert2')
window._ = require('lodash')
window.$ = window.jQuery = require('jquery');
window.moment = require('moment')

Vue.config.productionTip = false

axios.defaults.headers.common['Authorization'] = 'Bearer '+ localStorage.token;

Vue.component('secondary-header', require('./layouts/SecondaryHeader.vue').default);
Vue.component('main-footer', require('./layouts/MainFooter.vue').default);
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('float-bottom', require('./components/FloatBottom.vue').default);
Vue.component('loader', require('./components/Loader.vue').default);
Vue.component('favourite-icon', require('./components/FavouriteIcon.vue').default);

window.isAuth = function() {
    return !!localStorage.token
}

window.fireLoader = function(text = 'Waiting...') {
    Swal.fire({
        text: text,
        showConfirmButton: false,
        onBeforeOpen: () => Swal.showLoading(),
        allowOutsideClick: false
    })
    // stop loader with Swal.close()
}

window.addError = function(key, value) {
    $('[name="'+key+'"]').addClass('is-invalid').after('<div class="invalid-feedback">'+ value +'</div>')
},

window.removeError = function(field) {
    if(field)
        $('[name="'+ field +'"]').removeClass('is-invalid').next('.invalid-feedback').remove();
    else
        $(':input.form-control').removeClass('is-invalid').next('.invalid-feedback').remove();
}

window.defaultErrorManagement = function(error) {
    if(!error) {
        Swal.fire({
            text: 'Something went wrong. Please try again',
            timer: 5000
        })
    }
    else if(error.status == 401) {
        Swal.fire({
            text: 'Session expired. Please login again',
            timer: 5000,
            onClose: () => {
                localStorage.clear();
                window.ReactNativeWebView.postMessage("WINDOW_CLOSED")
            }
        })
    }
    else if(error.status == 422) {
        Swal.fire({
            text: error.data.message,
            timer: 5000,
        })

        removeError()

        $.each(error.data.errors, (key, value) => {
            addError(key, value[0])
        })

        $('.form-control.is-invalid:first').focus()

    } else {
        Swal.fire({
            html: '<span class="badge badge-light mr-2">'+ error.status +'</span>Something went wrong. Please try again',
            timer: 5000
        })
    }
}

Vue.mixin({
    data() {
        return {
            state: {
                loading: false
            },

            isAuth: isAuth(),

            customer_support_no: '016-8300065 / 016-8300109',

            GLOBAL: {
                API_BASE_URL: process.env.VUE_APP_API_BASE_URL,
                MAIN_GONET_URL: process.env.VUE_APP_MAIN_GONET_URL,
                MAIN_GONET_BACKEND_URL: process.env.VUE_APP_MAIN_GONET_BACKEND_URL,

                GONET_ADMIN_URL: process.env.VUE_APP_GONET_ADMIN_URL,

                BUILD_VERSION: '0.4.5'
            }
        }
    },

    methods: {
        lang(message, var_arr) {
            if(var_arr) {
                for (var key in var_arr) {
                    if (var_arr.hasOwnProperty(key)) {
                        message = message.replace(':'+key, var_arr[key])
                    }
                }
            }
            return message
        },

        money(value, unit = true) {
            if(value == undefined) value = 0
            var return_value = String(value)
            if(return_value) {
                return_value = return_value.replace(',','') //remove comma(,)
                return_value = return_value.replace(' ','') //remove space( )
            } else {
                return_value = 0
            }
            return (unit ? 'RM ' : '') + _.round(return_value, 2).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
        },

        number(value) {
            if(value == undefined) value = 0
            var return_value = String(value)
            if(return_value) {
                return_value = return_value.replace(',','') //remove comma(,)
                return_value = return_value.replace(' ','') //remove space( )
            } else {
                return_value = 0
            }
            return parseInt(return_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace('.00','')
        },

        dateUi(date, format) {
            return moment(date).format(format ? format : 'DD-MM-YYYY')
        },

        goBack(range) {
            router.go(range)
        },

        localStorageGetItem(key) {
            return localStorage.getItem(key)
        },
    }
})

new Vue({
  router,
  render: h => h(App),

  created() {
        // auth for first load
        if (router.currentRoute.matched.some(record => record.meta.requiresAuth)) {
            // this route requires auth, check if logged in
            // if not, close browser.
            if(!this.isAuth) {
                window.ReactNativeWebView.postMessage("WINDOW_CLOSED")
            }
        }
        else if(router.currentRoute.matched.some(record => record.meta.requiresGuest)) {
            // guest only
            // if logged in redirect to home
            if(isAuth()) {
                router.push('/home')
            }
        }
    },

  mounted() {
        var ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('safari') != -1) {
            if (ua.indexOf('chrome') > -1) {
                // Chrome
            } else {
                // Safari
                $('body').addClass('safari')
            }
        }

        $('<span>')
            .addClass('build-version')
            .html(this.GLOBAL.BUILD_VERSION)
            .appendTo('body')
    }
}).$mount('#app')


router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, close browser.
        if(!isAuth()) {
            window.ReactNativeWebView.postMessage("WINDOW_CLOSED")
        } else {
            next()
        }
    }
    else if(to.matched.some(record => record.meta.requiresGuest)) {
        // guest only
        // if logged in redirect to home
        if(isAuth()) {
            next({name: 'home'})
        } else {
            next()
        }
    } else {
        next() // make sure to always call next()!
    }
})
