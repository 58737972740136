<template>
    <div class="">
        <slot></slot>

        <div class="modal fade" id="modal-new-address" tabindex="-1" role="dialog" aria-labelledby="modal-new-addressTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <form @submit.prevent="submitAddress">
                            <div class="form-group">
                                <label for="recipient_name">{{ lang('Recipient Name') }}</label>
                                <input type="text" name="recipient_name" class="form-control" id="recipient_name" v-model="inputs.recipient_name">
                            </div>
                            <div class="form-group">
                                <label for="phone_no">{{ lang('Contact No') }}</label>
                                <input type="text" name="phone_no" class="form-control" id="phone_no" v-model="inputs.phone_no">
                            </div>
                            <div class="form-group">
                                <label for="address_text">{{ lang('Address') }}</label>
                                <textarea type="text" name="address_text" class="form-control" id="address_text" v-model="inputs.address_text" rows="4" />
                            </div>
                            <div class="text-right">
                                <button type="button" class="btn btn-danger mr-2" data-dismiss="modal">{{ lang('Cancel') }}</button>
                                <button type="submit" class="btn btn-primary">{{ lang('Save') }}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['default_value'],

    data() {
        return {
            inputs: {},
        }
    },

    methods: {
        submitAddress() {
            if(this.inputs.id) {
                this.update()
            } else {
                this.create()
            }
        },

        create() {
            this.$set(this.state, 'loading', true)
            axios.post(this.GLOBAL.API_BASE_URL +"customer-address", this.inputs)
                .then(response => {
                    this.$emit('address-created')
                    $('#modal-new-address').modal('hide');
                })
                .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
                .finally(() => this.$set(this.state, 'loading', false))
        },

        update() {
            this.$set(this.state, 'loading', true)
            axios.put(this.GLOBAL.API_BASE_URL +"customer-address/"+ this.inputs.id, this.inputs)
                .then(response => {
                    this.$emit('address-created')
                    $('#modal-new-address').modal('hide');
                })
                .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
                .finally(() => this.$set(this.state, 'loading', false))
        },

        editAddress(address) {
            console.log(address)
        }
    },

    mounted() {
        $('#modal-new-address').on('hidden.bs.modal', (e) => {
            this.inputs = {}
        })

        $('#modal-new-address').on('show.bs.modal', (e) => {
            this.$emit('address-modal-closed')
            if(this.default_value) {
                this.inputs = this.default_value
            }
        })
    }
}
</script>

<style lang="scss" scoped>
</style>
